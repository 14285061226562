import { DiscountCodeExtend } from "./DiscountCodeDTO";
import { OrderExtend } from "./order/OrderDTO";
import { OrderItemExtend } from "./order/OrderItemDTO";
import { PaymentOnPlaceExtend } from "./PaymentOnPlaceDTO";
import { PriceExtend } from "./PriceDTO";
import { ProductExtend } from "./product/ProductDTO";
import { ProductEpassDTO } from "./product/ProductEpassDTO";
import { RestrictionAccessDTO } from "./RestrictionAccessDTO";
import { WorkshopExtend } from "./WorkshopDTO";

export class ArchiveDTO {
  public id = 0;
  public orgaId = 0;
  public title = "";
  public date = "";
  public status = ""; // archive | draft | visible
  public isArchiveId0 = false;
  public position = 0;

  constructor(init?: Partial<ArchiveDTO>) {
    if (!init) return;
    Object.assign(this, init);
  }
}
export class ArchiveExtend extends ArchiveDTO {
  public workshops: WorkshopExtend[] = [];
  public orders?: OrderExtend[];
  public prices?: PriceExtend[];
  public discountCodes?: DiscountCodeExtend[];
  public restrictions?: RestrictionAccessDTO[];
  public paymentOnPlaces?: PaymentOnPlaceExtend[];
  public epass?: ProductEpassDTO[];
  public products?: ProductExtend[];
  public orderItems?: OrderItemExtend[];

  constructor(init?: Partial<ArchiveExtend>) {
    super();
    if (init === undefined) return;
    Object.assign(this, init);
  }
}
